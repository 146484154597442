<template>
    <div>
        <Header></Header>
        <div class="content screen_content">
            <div class="screen_list">
                <div class="screen_list_left">
                    方向
                </div>
                <div class="screen_list_right">
                    <div class="right_item" :class="{this:current == index}" @click="clicks(index)" v-for="(item,index) in list" :key="index">
                        {{item.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="list_content content">
            <div class="list_screen spjz">
                <div class="list_screen_item">
                    资讯列表
                </div>
                <div class="list_screen_right">
                    共<span>{{ count }}</span>个结果
                </div>
            </div>
            <div class="news_list">
                <div class="news_item cup" v-for="(item,index) in video_list" :key="item.id" @click="links_news(item.id,item.inforType)">
                    <div class="left_img" v-if="item.inforType == 2 || item.inforType == 1">
                        <img :src="item.faceUrl ? item.faceUrl : ''" alt="">
                    </div>
                    <div class="left_img" v-if="item.inforType == 3">
                        <img :src="item.urls ? item.urls[0] : ''" alt="">
                    </div>
                    <div class="right_content">
                        <h1 class="caochu">{{ item.content }}</h1>
                        <div class="content-bottom"> 
                            <div class="mr15 cup" style="display: flex;align-items: center;" @click.stop="like(item.id,item.mid,index)"><span  class="iconfont icon-thumbup-fill aaa" :class="item.isPraise!=0 ? 'on' : ''" style="font-size: 22px;"></span> &nbsp;{{item.praiseNum>=10000?changeUnits(item.praiseNum):item.praiseNum}}&nbsp;赞</div>
                            <div>{{config.miniApps || ''}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <nodata v-if="video_list.length == 0"></nodata>
            <div class="spczjz mt10">
                <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="video_current"
                :page-size="12"
                hide-on-single-page
                layout="prev, pager, next, jumper"
                :total="count">
                </el-pagination>
            </div>
        </div>
        <gap height="50"></gap>
        <Homefooter ></Homefooter>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { sourc,sourc2,pageVideo,selectByPid,pageCompanyList,addDynamicPraise,selectBySingle } from "@/apiUrl/index.js";
    export default {
        computed: {
            ...mapGetters(["token","config"]),
        },
        data() {
            return {
                sourc,sourc2,
                list:[],
				current:0,
				video_current:1,
				videoType:'',
				videoTypeId:'',
				video_list:[],
				isOK:true,
				type:1,
				icon_name:'',
				lwltab:true,
				list2:[],
				current2:0,
				id:'', // 一级分类id
				twoType:'', // 二级分类id
				videoTypes:'', // 三级分类id
                count:0, // 总数
                version:this.$store.state.version,
                recommendType:''
            };
        },
        created() {
            var {videoType,twoType,id,icon_name,videoTypeId,recommendType} = this.$route.query;
			this.id = id || '';
			this.icon_name = icon_name || '每日案例';
			this.videoTypeId = videoTypeId || '';
            this.recommendType = recommendType;
			if(videoType && twoType) { // 从分类中进来
				this.twoType = twoType;
				this.videoTypes = videoType;
				this.getChildData();
			} else {
				this.getdata();
				this.gettabs();
			}
        },
        methods: {
            links_news(id,type) {
                if(type == 1) {
                    this.$links('/news_detail?id=' + id)
                } else if(type == 2) {
                    this.$links('/v_play?id=' + id)
                } else if(type == 3) {
                    this.$links('/news_detail2?id=' + id)
                }
            },
            like(id,mid,index) {
				var el = this;
				addDynamicPraise({did:id}).then((e)=>{
					if(e.code==200){
						if(el.video_list[index].isPraise==0){
                            this.$msg('点赞成功！')
							el.video_list[index].isPraise=1;
							el.video_list[index].praiseNum++;
						}else{
                            this.$msg('已取消点赞！')
							el.video_list[index].isPraise=0;
							el.video_list[index].praiseNum--;
						}
					}else{
                        this.$msg(e.message,2)
					}
				})
			},
            changeUnits(num) {
				let val = num/10000;
				val = val.toFixed(1);
				return val + '万'
			},
            // 视频详情
            link_detail(id,type,vipSet) {
                if(type==1){
                    this.$link('/video_detail?id=' + id + '&mode=video')
				}else if(type==2){
					if(vipSet==2){
                        this.$link('/video_detail2?id=' + id + '&mode=video')
					}else{
						this.$link('/video_detail1?id=' + id + '&mode=video')
					}
				}              
            },
            handleCurrentChange(val) {
                this.video_current = val;
                this.video_list = [];
                this.getdata();
            },
            handleSizeChange(val) {
                this.video_current = val;
                this.video_list = [];
                this.getdata();
            },
            changSecond(e) { //转换成秒
				return this.$secondToMinutes(e)
			},
            changeUnits(num) {
				let val = num/10000;
				val = val.toFixed(1);
				return val + '万'
			},
            getChildData () {
				this.gettabs().then(res => {
					if(this.list && this.list.length ) {
						this.list.some((item,index) => { // 选中二级分类
							if(item.id == this.twoType) {
								this.current = index
								return true
							}
						})
						selectByPid({pid:this.twoType}).then(res => { // 选中三级分类
							if(res.code == 200) {
								this.list2 = res.data;
								if(this.list2 && this.list2.length ) {
									this.list2.some((item,index) => { // 选中二级分类
										if(item.id == this.videoTypes) {
											this.current2 = index
											return true
										}
									})
								}
								this.getdata();
							}
						})
					}
				});
			},
			async gettabs() {
                if(this.recommendType!= 1) {
                    return selectByPid({pid:this.id}).then(res => {
                        if(res.code == 200) {
                            var teacherMenuList = [{name:'全部',id:''}]
                            this.list = teacherMenuList.concat(res.data);
                        }
                    })
                } else {
                    return selectBySingle({mark:'publicize',form:'form'}).then(res => {
                        if(res.code == 200) {
                            var teacherMenuList = [{name:'全部',id:''}]
                            this.list = teacherMenuList.concat(res.data);
                        }
                    })
                }
				
			},
			getdata(){
				let form = {current:this.video_current,size:12,inforId:this.twoType}
                if(this.recommendType == 1) {
                    form.recommendType = 1
                }
				pageCompanyList(form).then((res)=>{
					if(res.code==200){
						var arr = res.data.list;
                        arr.forEach((v,i)=>{1
							if(v.fileType==1&&v.urls){
								arr[i].urls = v.urls.split(',')
							}
						})
						this.video_list = arr;
                        this.count = res.data.count || 0;
					}else{
						this.$msg(res.message);
					}
				})
			},
            clicks(e){
				this.current = e;
				this.video_list = [];
				this.isOK = true;
				this.video_current = 1;
				this.twoType = this.list[this.current].id||'';
				this.videoTypes = '';
                console.log(this.current)
                if(this.current == 0) {
                    this.list2 = [];
                } else {
                    selectByPid({pid:this.twoType}).then(res => {
                        if(res.code == 200) {
                            if(res.data.length > 0) {
                                var list = [{name:'全部',id:''}]
                                this.list2 = list.concat(res.data);
                                this.current2 = 0;
                            }
                            
                        }
                    })
                }
				
				this.getdata();
			},
			clickss(e){
				this.current2 = e;
				this.video_list = [];
				this.isOK = true;
				this.video_current = 1;
				this.videoTypes = this.list2[this.current2].id||'';
				
				this.getdata();
			},
        },
    };
</script>
<style lang="less" scoped>
    .aaa {
        color: #999aaa;
    }
    .on {
        color: var(--color);
    }
    .screen_content{
        background-color: #fff;
        border-radius: 6px;
        margin-top: 16px;
        padding: 20px 20px 16px 20px;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 10px;
    }
    .screen_list{
        display: flex;
        margin-bottom: 16px;
    }
    .screen_list:last-child{
        margin-bottom: 0;
    }
    .screen_list_left{
        display: inline-block;
        text-align: center;
        width: 48px;
        margin-top: 1px;
        height: 28px;
        line-height: 28px;
        background: #f5f7f8;
        color: #1c1d30;
        font-weight: 500;
        border-radius: 17px 0 17px 17px;
        margin-right: 16px;
    }
    .screen_list_right{
        width: 1000px;
    }
    .right_item{
        cursor: pointer;
        display: block;
        float: left;
        height: 28px;
        line-height: 28px;
        padding: 0 8px;
        border: 0;
        outline: 0;
        border-radius: 17px;
        margin-right: 8px;
        margin-bottom: 8px;
        color: var(--font-color);
    }
    .right_item:hover{
        color: var(--color);
    }
    .right_item.this{
        color: var(--color);
        background-color: #f3f6ff;
    }
    .screen_del{
        position: absolute;
        top: 20px;
        right: 16px;
        line-height: 28px;
        cursor: pointer;
        box-shadow: none;
        height: 32px;
        padding: 0 15px;
        color: var(--font-color);
    }
    .list_content{
        background-color: #fff;
        padding: 16px 24px;
        box-sizing: border-box;
    }
    .list_screen{
        position: relative;
        margin-bottom: 12px;
        padding-left: 8px;
    }
    .list_screen_item{
        margin-right: 36px;
        font-size: 16px;
        position: relative;
        line-height: 24px;
        color: var(--font-color);
    }
    .list_screen_item.this{
        color: #1c1d30;
    }
    .list_screen_item.this:after {
        content: "";
        display: block;
        width: 20px;
        height: 2px;
        border-radius: 2px;
        background: #1c1d30;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        bottom: -3;
    }
    .input-wrapper{
        display: inline-block;
        position: relative;
        input{
            width: 252px;
            height: 32px;
            border-radius: 21px;
            padding: 0 24px 0 16px;
            background-color: #fff;
            border: 1px solid #dcdee2;
            box-sizing: border-box;
        }
        input:focus {
            border: 1px solid var(--color) !important;
        }
        input:focus-visible {
            outline: none;
        }
        .icon-sousuo1 {
            width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 22px;
            text-align: center;
            color: #808695;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 3;
        }
    }
    .list_screen_right{
        position: absolute;
        right: 0px;
        color: #515a6e;
        span{
            color: #222;
        }
    }
    .news_list {
        padding: 10px;
        padding-top: 0;
        .news_item {
            padding: 20px 0;
            border-bottom: 1px solid #f0f0f2;
            display: flex;
            // align-items: center;
            .left_img {
                width: 176px;
                height: 99px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .right_content {
                font-size: 18px;
                margin-left: 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .content-bottom {
                    display: flex;
                    align-items: center;
                }
            }
        }
        .news_item:hover{
            background-color: #f7f7fc;
        }
        .news_item:last-child {
            border-bottom: 0;
        }
    }
</style>
  